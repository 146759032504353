import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Title from '../components/Title';

const buttonStye = {
    display: 'block',
    marginTop: '1rem',
    textAlign: 'center',
};

const NotFoundPage = () => (
    <Layout>
        <main>
            <Title pre="sorry">
                <h1 id="initialClaim1" className="claim">
                    That's a 404
                </h1>
                <p>
                    And it means you probably were looking for something{' '}
                    <span className="strike">that doesn't exist</span>.
                </p>
                <p>But if you really want it, we can do it.</p>
                <Link to="/contact" className="cta-button super goesUp" style={buttonStye}>
                    let's create it
                </Link>
            </Title>
        </main>
    </Layout>
);

export default NotFoundPage;
